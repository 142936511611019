
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { database } from '../../firebase';
import { useAuth } from "contexts/AuthContext";

import { ThemeProvider, Tabs, Stack, Element } from 'react-ui'
import theme from '../../theme'


function CodeView() {
  const { currentUser } = useAuth();
  const { componentId } = useParams();
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState({ name: '', content: '' });
  const [consoledata, setConsoledata] = useState([]);
  const iframeRef = useRef(null);
  useEffect(() => {
    database.files
      .doc(componentId)
      .get()
      .then((doc) => {
        setCurrentComponent(database.formatDoc(doc));
      })
      .catch(() => {
        history?.push('/');
      });
    // eslint-disable-next-line
  }, [componentId]);



  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentComponent.content) {
        if (iframeRef && iframeRef.current) {
          iframeRef.current.contentWindow.postMessage(currentComponent.content, '*');
          iframeRef.current.contentWindow.console.addEventListener("log", function (value) {
            consoledata.push(value)
            setConsoledata(consoledata)
          })
        }
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [currentComponent?.content]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Element as="main" css={{ display: 'flex', height: '100vh' }}>
          <iframe
            srcDoc={currentComponent?.content}
            className="frame"
            title={"pg"}
            width={'100%'}
            height={'100%'}
            sandbox="allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation"
          />
        </Element>
      </ThemeProvider>
    </>
  );
}





export default CodeView;
