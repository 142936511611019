import React, { useRef, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import "./style.css";

import Car from "./system/car";
import Road from "./system/road";
import Visualizer from "./system/visualizer";
import { NeuralNetwork } from "./system/network";
import { lerp, getIntersection, polysIntersect, getRGBA, getRandomColor } from "./system/utils";
var bestCar;
export default function CarNeuralnetwork() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const carCanvas = document.getElementById("carCanvas");
        carCanvas.width = 200;
        const networkCanvas = document.getElementById("networkCanvas");
        networkCanvas.width = 500;

        const carCtx = carCanvas.getContext("2d");
        const networkCtx = networkCanvas.getContext("2d");

        const road = new Road(carCanvas.width / 2, carCanvas.width * 0.9);

        const N = 100;
        const cars = generateCars(N);
        bestCar = cars[0];
        if (localStorage.getItem("bestBrain")) {
            for (let i = 0; i < cars.length; i++) {
                cars[i].brain = JSON.parse(
                    localStorage.getItem("bestBrain"));
                if (i != 0) {
                    NeuralNetwork.mutate(cars[i].brain, 0.1);
                }
            }
        }

        const traffic = [
            new Car(road.getLaneCenter(1), -100, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(0), -300, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(2), -300, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(0), -500, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(1), -500, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(1), -700, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(2), -700, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(1), -1700, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(2), -1700, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(1), -1100, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(0), -1300, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(0), -1000, 30, 50, "DUMMY", 2, getRandomColor()),
            new Car(road.getLaneCenter(2), -400, 30, 50, "DUMMY", 2, getRandomColor()),
        ];

        animate();

        function generateCars(N) {
            const cars = [];
            for (let i = 1; i <= N; i++) {
                cars.push(new Car(road.getLaneCenter(1), 100, 30, 50, "AI"));
            }
            return cars;
        }

        function animate(time) {
            for (let i = 0; i < traffic.length; i++) {
                traffic[i].update(road.borders, []);
            }
            for (let i = 0; i < cars.length; i++) {
                cars[i].update(road.borders, traffic);
            }
            bestCar = cars.find(
                c => c.y == Math.min(
                    ...cars.map(c => c.y)
                ));
            console.log(bestCar)
            carCanvas.height = window.innerHeight;
            networkCanvas.height = window.innerHeight;

            carCtx.save();
            carCtx.translate(0, -bestCar.y + carCanvas.height * 0.7);

            road.draw(carCtx);
            for (let i = 0; i < traffic.length; i++) {
                traffic[i].draw(carCtx);
            }
            carCtx.globalAlpha = 0.2;
            for (let i = 0; i < cars.length; i++) {
                cars[i].draw(carCtx);
            }
            carCtx.globalAlpha = 1;
            bestCar.draw(carCtx, true);

            carCtx.restore();

            networkCtx.lineDashOffset = -time / 50;
            Visualizer.drawNetwork(networkCtx, bestCar.brain);
            requestAnimationFrame(animate);
        }
    }, [])
    const save = () => {
        localStorage.setItem("bestBrain",
            JSON.stringify(bestCar.brain));
    }

    const discard = () => {
        localStorage.removeItem("bestBrain");
    }
    return (
        <div className="bg-gray-200 flex items-center font-sans text-gray-700 h-screen">
            <div className="container mx-auto p-4 flex">
                <button onClick={() => save()}>💾</button>
                <button onClick={() => discard()}>🗑️</button>
                <div className="max-w-md w-full mx-auto">
                    <canvas id="carCanvas"></canvas>

                    <canvas id="networkCanvas"></canvas>
                </div>
            </div>
        </div>
    );
}
