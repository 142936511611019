import Editor from '@monaco-editor/react';
import Layout from 'components/Layout';
import Sidebar from 'components/Editor/sidebar';
import NodeConsole from 'components/Editor/NodeConsole';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { database } from '../../firebase';
import { useAuth } from "contexts/AuthContext";

import Browser from 'components/Editor/Browser'
import { ThemeProvider, Tabs, Stack, Element } from 'react-ui'
import { tokens, components } from 'react-ui/themes/base'
import * as Icons from 'components/icon'
import theme from '../../theme'
components.TabList = {
  background: '#000',
  borderColor: 'grey'
}
components.Tab = {
  outline: 'none',
  padding: '0.5rem 0.5rem !important',
  color: '#ffff',

  ':hover': {
    color: 'green',
  },
  '&[data-selected]': {
    color: 'green',
    borderWidth: '5px !important',
  }

}
components.TabPanel = {
  // minHeight: '500px',
  fontSize: 3,
  padding: 0,
  outline: 'none'
}


function FileIcon({ name, extension }) {
  const Icon = Icons[extension] || Icons[name]
  return (
    <Element
      as="span"
      css={{
        display: 'flex',
        width: 32,
        height: 32,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Icon />
    </Element>
  )
}



function CodePreview() {
  const { currentUser } = useAuth();
  const { componentId } = useParams();
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useState({ name: '', content: '' });
  const [consoledata, setConsoledata] = useState([]);
  const iframeRef = useRef(null);
  const iframeSrcDoc = `
    <html>
      <head>
        <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet" />
        <script>
          window.onmessage = function(e) {
            if (e.data) {
              document.body.innerHTML = e.data;
            }
          };
        </script>
      </head>
      <body></body>
    </html>
  `;
  useEffect(() => {
    database.files
      .doc(componentId)
      .get()
      .then((doc) => {
        setCurrentComponent(database.formatDoc(doc));
      })
      .catch(() => {
        history?.push('/');
      });
    // eslint-disable-next-line
  }, [componentId]);



  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentComponent.content) {
        if (iframeRef && iframeRef.current) {
          iframeRef.current.contentWindow.postMessage(currentComponent.content, '*');
          iframeRef.current.contentWindow.console.addEventListener("log", function (value) {
            consoledata.push(value)
            setConsoledata(consoledata)
          })
        }
      }
    }, 250);

    return () => clearTimeout(timeout);
  }, [currentComponent?.content]);

  const handleSave = () => {
    if (currentComponent?.name === '') {
      alert('Component name is required!');
      return;
    }
    database.files
      .doc(componentId)
      .update(currentComponent)
      .then(() => alert('Component Saved!'));
  };

  const handleDelete = () => {
    database.files
      .doc(componentId)
      .delete()
      .then(() => {
        alert('Component Deleted!');
        history.push('/');
      });
  };



  return (
    <>
      <ThemeProvider theme={theme}>
        <ThemeProvider tokens={tokens} components={components}>
          <Tabs>

            <Tabs.Tab label={
              <Stack as="span" inline align="center" gap={2}>
                <FileIcon name={'html' || 'File'}
                  extension={'html'} />
                HTML
              </Stack>
            }>

            </Tabs.Tab>
            {/* <Tabs.Tab label={
              <Stack as="span" inline align="center" gap={2}>
                <FileIcon name={'css' || 'File'}
                  extension={'css'} />
                CSS
              </Stack>
            }>
              test
            </Tabs.Tab>
            <Tabs.Tab label={
              <Stack as="span" inline align="center" gap={2}>
                <FileIcon name={'js' || 'File'}
                  extension={'js'} />
                JS
              </Stack>
            }>
              test
            </Tabs.Tab> */}
          </Tabs>
        </ThemeProvider>
        <Element as="main" css={{ display: 'flex' }}>
          {/* <Sidebar>
          </Sidebar> */}
          <div style={{ width: '40%', height: 'calc(100vh - 55px)' }}>
            <Editor
              theme="vs-dark"
              language="html"
              value={currentComponent?.content}
              onChange={(value) => setCurrentComponent((pre) => ({ ...pre, content: value }))}
              loading={'Loading...'}
            />
            {/* <NodeConsole
              start={true}
              user={"mtf"}
              logs={consoledata}
              throws={["missing ) after argument list"]}
            /> */}
          </div>
          <div style={{ width: '60%', height: 'calc(100vh - 55px)' }}>
            {/* <input
              type="text"
              className="mt-12 text-3xl text-center bg-transparent"
              maxLength="30"
              value={currentComponent?.name}
              onChange={(e) => {
                setCurrentComponent((pre) => ({ ...pre, name: e.target.value }));
              }}
            /> */}
            {/* <iframe
              ref={iframeRef}
              srcDoc={iframeSrcDoc}
              title="output"
              sandbox="allow-scripts"
              frameBorder="0"
              width="100%"
              height="100%"
            /> */}
            <Browser
              title="MTF IN TH"
              secure={true}
              host={"localhost"}
              port={""}
              content={`${currentComponent.content}`}
              width={"100%"}
              height={"100%"}
              logs={["23", "25"]}
              throws={["missing ) after argument list"]}
              Menu={() => (
                <>
                  {
                    currentUser &&
                    <>
                      <div className="other" onClick={handleDelete}>
                        ลบ
                      </div>
                      <div className="other" onClick={handleSave}>
                        บันทึก
                      </div>
                    </>
                  }
                </>
              )}
              history={history}
            />
          </div>
        </Element>
      </ThemeProvider>
      {/* {
        currentUser &&
        <div className="flex justify-center my-5 space-x-5">
          <button
            onClick={handleSave}
            className="py-3 text-white rounded-full bg-primary-600 hover:bg-primary-500 w-28">
            Save
          </button>
          <button
            onClick={handleDelete}
            className="py-3 text-white rounded-full bg-secondary-500 hover:bg-primary-500 w-28">
            Delete
          </button>
        </div>
      } */}
      {/* <div style={{ height: 'calc(100vh - 72px)' }} className="relative flex flex-col">
        <div className="flex flex-col-2 flex-grow w-full max-w-3xl grid-cols-2 mx-auto">
          <div className="mt-5 h-1/2">
          </div>
          <div className="p-5 mt-5 bg-white rounded shadow">
          </div>
        </div>
      </div> */}
    </>
  );
}





export default CodePreview;
