import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faLock,
  faExclamationCircle,
  faArrowLeft,
  faArrowRight,
  faRedo,
  faLightbulb,
  faTerminal,
  faAngleRight,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

import "./console.css";
import "./browser.css";

export default ({
  secure = true,
  host = "playground.digitalhouse.com",
  port = "",
  favicon = "",
  title = "Playground Digital House",
  content,
  width = "100%",
  height = "250px",
  logs = [],
  throws = [],
  Menu,
  history
}) => {
  const iframeRef = useRef(null);
  const [random, setRandom] = useState(0);
  const [theme, setTheme] = useState("dark");
  const [showConsole, setShowConsole] = useState(false);
  const [logsList, setLogsList] = useState([1, 2]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (iframeRef && iframeRef.current) {
        console.log(iframeRef.current.contentWindow)
        iframeRef.current.contentWindow.console.log = function (message) {
          logsList.push(message)
          setLogsList(logsList)
          console.log(logsList)
        }
      }
    }, 1000);
    // const timeout = setTimeout(() => {
    //   if (iframeRef && iframeRef.current) {
    //     iframeRef.current.contentWindow.console.addEventListener("log", function (value) {
    //       console.log.apply(null, value);
    //     })
    //   }
    // }, 250);
    // return () => clearTimeout(timeout);
  }, [iframeRef]);
  const url = () => {
    let protocolo = "http";
    let icon = (
      <span className="protocolo">
        <FontAwesomeIcon icon={faExclamationCircle} />
      </span>
    );
    if (secure) {
      icon = (
        <span className="protocolo">
          <FontAwesomeIcon icon={faLock} />
        </span>
      );
      protocolo = "https";
    }
    return (
      <>
        {icon}
        {protocolo}://{host}
        {port}
      </>
    );
  };
  const reDo = () => setRandom(random + 1);
  const toggleTheme = () =>
    theme === "light" ? setTheme("dark") : setTheme("light");
  const toggleConsole = () => setShowConsole(!showConsole);

  return (
    <div className={`Browser ${theme}`}>
      <div className="options">
        <ul className="navigation">
          <li onClick={reDo} key="backArrow">
            <FontAwesomeIcon icon={faArrowLeft} />
          </li>
          <li key="nextArrow">
            <FontAwesomeIcon icon={faArrowRight} />
          </li>
          <li onClick={reDo} key="reDo">
            <FontAwesomeIcon icon={faRedo} />
          </li>
        </ul>
        <div className="url">{url()}</div>
        <div className="theme" onClick={toggleTheme}>
          <FontAwesomeIcon icon={faLightbulb} />
        </div>
        {/* <div className="terminal" onClick={toggleConsole}>
          <FontAwesomeIcon icon={faTerminal} />
        </div> */}
        <Menu />
        <div className="other" onClick={() => history.goBack()}>
        Home
      </div>
      <div className="both" />
    </div>
      {
    content ? (
      <div className="renderArea">
        {showConsole && (
          <BrowserConsole
            width={"350px"}
            height={"350px"}
            logs={logsList}
            throws={throws}
            close={setShowConsole}
          />
        )}
        <iframe
          ref={iframeRef}
          srcDoc={content}
          className="frame"
          key={random}
          title={"pg"}
          width={width}
          height={height}
          sandbox="allow-forms allow-scripts allow-same-origin allow-modals allow-popups allow-presentation"
        />
      </div>
    ) : (
      <div className="emptyArea" style={{ height, width }}>

      </div>
    )
  }
    </div >
  );
};

const BrowserConsole = ({
  logs = [],
  throws = [],
  height = "100%",
  width = "350px",
  close = () => { }
}) => {
  return (
    <div className="BrowserConsole" style={{ width, height }}>
      <div className="header">
        <div className="closeBtnConsole" onClick={() => close(false)}>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <div className="consoleTitle">Console</div>
      </div>
      <div className="logArea">
        {logs.map((log, i) => (
          <>
            <span className="grayLog" key={`log${i}`}>
              <FontAwesomeIcon icon={faAngleRight} /> {log}
            </span>
          </>
        ))}
        {throws.map((t, i) => (
          <>
            <span className="throwError" key={`throw${i}`}>
              <FontAwesomeIcon icon={faTimes} /> ThrowError: {t}
            </span>
          </>
        ))}
      </div>
    </div>
  );
};
