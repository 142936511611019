import React from 'react'

export const ClosedDirectory = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6674 9.70666L15.8096 9.83333H16H26C26.2761 9.83333 26.5 10.0572 26.5 10.3333V25C26.5 25.2761 26.2761 25.5 26 25.5H6C5.72386 25.5 5.5 25.2761 5.5 25V8C5.5 7.72386 5.72386 7.5 6 7.5H13.0001C13.1228 7.5 13.2411 7.54508 13.3327 7.62667L15.6674 9.70666Z"
      fill="#64D2FF"
      stroke="#64D2FF"
    />
  </svg>
)

export const OpenDirectory = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6674 9.70666L15.8096 9.83333H16H26C26.2761 9.83333 26.5 10.0572 26.5 10.3333V25C26.5 25.2761 26.2761 25.5 26 25.5H6C5.72386 25.5 5.5 25.2761 5.5 25V8C5.5 7.72386 5.72386 7.5 6 7.5H13.0001C13.1228 7.5 13.2411 7.54508 13.3327 7.62667L15.6674 9.70666Z"
      fill="transparent"
      stroke="#64D2FF"
    />
  </svg>
)

export const js = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m3 3h18v18h-18v-18m4.73 15.04c.4.85 1.19 1.55 2.54 1.55 1.5 0 2.53-.8 2.53-2.55v-5.78h-1.7v5.74c0 .86-.35 1.08-.9 1.08-.58 0-.82-.4-1.09-.87l-1.38.83m5.98-.18c.5.98 1.51 1.73 3.09 1.73 1.6 0 2.8-.83 2.8-2.36 0-1.41-.81-2.04-2.25-2.66l-.42-.18c-.73-.31-1.04-.52-1.04-1.02 0-.41.31-.73.81-.73.48 0 .8.21 1.09.73l1.31-.87c-.55-.96-1.33-1.33-2.4-1.33-1.51 0-2.48.96-2.48 2.23 0 1.38.81 2.03 2.03 2.55l.42.18c.78.34 1.24.55 1.24 1.13 0 .48-.45.83-1.15.83-.83 0-1.31-.43-1.67-1.03l-1.38.8z"
        fill="#ffca28"
      />
    </svg>
  )
}

export const css = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m5 3l-.65 3.34h13.59l-.44 2.16h-13.58l-.66 3.33h13.59l-.76 3.81-5.48 1.81-4.75-1.81.33-1.64h-3.34l-.79 4 7.85 3 9.05-3 1.2-6.03.24-1.21 1.54-7.76h-16.94z"
        fill="#42a5f5"
      />
    </svg>
  )
}

export const json = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m5 3h2v2h-2v5a2 2 0 0 1 -2 2 2 2 0 0 1 2 2v5h2v2h-2c-1.07-.27-2-.9-2-2v-4a2 2 0 0 0 -2 -2h-1v-2h1a2 2 0 0 0 2 -2v-4a2 2 0 0 1 2 -2m14 0a2 2 0 0 1 2 2v4a2 2 0 0 0 2 2h1v2h-1a2 2 0 0 0 -2 2v4a2 2 0 0 1 -2 2h-2v-2h2v-5a2 2 0 0 1 2 -2 2 2 0 0 1 -2 -2v-5h-2v-2h2m-7 12a1 1 0 0 1 1 1 1 1 0 0 1 -1 1 1 1 0 0 1 -1 -1 1 1 0 0 1 1 -1m-4 0a1 1 0 0 1 1 1 1 1 0 0 1 -1 1 1 1 0 0 1 -1 -1 1 1 0 0 1 1 -1m8 0a1 1 0 0 1 1 1 1 1 0 0 1 -1 1 1 1 0 0 1 -1 -1 1 1 0 0 1 1 -1z"
        fill="#fbc02d"
      />
    </svg>
  )
}

export const html = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12 17.56l4.07-1.13.55-6.1h-7.24l-.18-2.03h7.6l.2-1.99h-10l.56 6.01h6.89l-.23 2.58-2.22.6-2.22-.6-.14-1.66h-2l.29 3.19 4.07 1.13m-7.93-14.56h15.86l-1.43 16.2-6.5 1.8-6.5-1.8-1.43-16.2z"
      fill="#e44d26"
    />
  </svg>
)

export const File = props => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1">
      <path d="M16.5689 5H9C8.44772 5 8 5.44772 8 6V26C8 26.5523 8.44772 27 9 27H23C23.5523 27 24 26.5523 24 26V12.1587L16.5689 5ZM16 6L23 13H16V6Z" />
    </mask>
    <path
      d="M16.5689 5H9C8.44772 5 8 5.44772 8 6V26C8 26.5523 8.44772 27 9 27H23C23.5523 27 24 26.5523 24 26V12.1587L16.5689 5ZM16 6L23 13H16V6Z"
      fill="#eee"
    />
    <path
      d="M16.0689 5V6.56889H17.0689V5H16.0689ZM22.1587 12.6587H24V11.6587H22.1587V12.6587ZM16.5689 5L17.2627 4.27982L16.9722 4H16.5689V5ZM24 12.1587H25V11.7335L24.6938 11.4385L24 12.1587ZM23 13V14H25.4142L23.7071 12.2929L23 13ZM16 6L16.7071 5.29289L15 3.58579V6H16ZM16 13H15V14H16V13ZM9 6H16.5689V4H9V6ZM9 6L9 6V4C7.89543 4 7 4.89543 7 6H9ZM9 26V6H7V26H9ZM9 26H7C7 27.1046 7.89543 28 9 28V26ZM23 26H9V28H23V26ZM23 26V28C24.1046 28 25 27.1046 25 26H23ZM23 12.1587V26H25V12.1587H23ZM15.8751 5.72018L23.3062 12.8789L24.6938 11.4385L17.2627 4.27982L15.8751 5.72018ZM23.7071 12.2929L16.7071 5.29289L15.2929 6.70711L22.2929 13.7071L23.7071 12.2929ZM16 14H23V12H16V14ZM15 6V13H17V6H15Z"
      fill="#eee"
      mask="url(#path-1-inside-1)"
    />
  </svg>
)
